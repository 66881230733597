export default {
  topPage: (): string => '/',
  aboutPage: (query?: string): string => (query ? `/about/${query}` : '/about/'),
  kagglePage: (): string => '/kaggle/',
  recruitPage: (): string => '/recruit/',
  contactPage: (): string => '/contact/',
  errorPage: () => '/404/',
  // News
  newsPage: (): string => '/news/',
  newsDetailPage: (slug: string): string => `/news/${slug}/`,
  // Works
  worksPage: (): string => '/works/',
  workDetailPage: (slug: string): string => `/works/${slug}/`,
  // Story
  storyPage: (): string => '/story/',
  storyDetailPage: (slug: string): string => `/story/${slug}/`,
  // tech posts
  techPostPage: () => '/techposts/',
  communityPage: () => '/community/',
  // summer internship
  summerInternshipPage: () => '/recruit/internship/'
}
