import newsTags from './news'

export default {
  title: 'DeNA×AI',
  titleTemplate: '%s | DeNA×AI',
  description: 'AIを活用した幅広いサービスの利用が当たり前になった昨今、これからの私たちが提供できる価値はDeNA×AIだからできる「人」と「テクノロジー」が、組み合わさった未来です。 DeNA×AIと新しいモノづくりを一緒に。',
  url: 'https://dena.ai/',
  locale: 'ja_JP',
  site_name: 'DeNA_AI',
  version: '1.0',
  newsTags,
  external_link: {
    blog: 'https://engineer.dena.com',
    twitter: 'https://twitter.com/DeNAxAI_NEWS',
    facebook: 'https://www.facebook.com/DeNAxAI/',
    privacy_policy: 'https://dena.com/jp/privacy/'
  }
}
