import { Context } from '@nuxt/types'

import config from '../config/'

function meta (name:string = '', content:string = '', extend:object = {}): object {
  return {
    name,
    hid: name,
    content,
    ...extend
  }
}

function metaProperty (property:string = '', content:string = '', extend:object = {}): object {
  return {
    property,
    hid: property,
    content,
    ...extend
  }
}

function link (rel:string = '', href:string = '', type:string = '', extend: object = {}) {
  return {
    rel,
    type,
    href,
    ...extend
  }
}

function setMetaTitleAndDescription (
  title: string = config.title,
  description: string = config.description,
  titleTemplate: boolean = true
): Array<any> {
  return [
    metaProperty('og:title', title, { template: titleTemplate ? config.titleTemplate : undefined }),
    meta('twitter:title', title, { template: titleTemplate ? config.titleTemplate : undefined }),

    meta('description', description),
    metaProperty('og:description', description),
    meta('twitter:description', description)
  ]
}

function blockScroll (): void {
  document.body.classList.add('block')
}

function unBlockScroll (): void {
  document.body.classList.remove('block')
}

function handleError (err: Error) {
  // eslint-disable-next-line no-console
  console.log(err)
}

function debounce (func: Function, wait: number = 20) {
  let timeout: any
  return function executedFunction (...args: any) {
    const later = () => {
      timeout = null
      func(...args)
    }
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
  }
}

export {
  meta,
  metaProperty,
  link,
  setMetaTitleAndDescription,
  handleError,
  blockScroll,
  unBlockScroll,
  debounce
}

const helper = {
  meta,
  metaProperty,
  link,
  setMetaTitleAndDescription,
  handleError,
  blockScroll,
  unBlockScroll,
  debounce
}
export type HelperType = typeof helper
export default (ctx: Context, inject: any) => {
  ctx.$helper = helper
  inject('helper', helper)
}
