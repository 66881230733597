import Vue from 'vue';
import Carousel from './carousel';

Vue.directive('carousel', {
  inserted(el, binding) {
    let options = binding.value || {};
    if(typeof options === 'function') {
      options = {
        callback: options,
      }
    }
    new Carousel(el, options);
  }
})
