const detectIt = require('detect-it')

const configDefault = {
  slidesToShow: 1,
  slidesToScroll: 1,
  slidesMargin: 0,
  slidesGroup: 1,
  btnNext: '#next',
  btnPrev: '#prev',
  dots: '#dots',
  isAutoPlay: false,
  duration: 3000,
  clsContent: 'carousel-content',
  clsSlide: 'carousel-slide',
  clsActive: 'slide-active',
  clsClone: 'is-clone',
  minSwipeValue: 30,
  canSwipe: false,
  container: '.container',
  isCallback: false,
  slideActive: 1,
  responsive: {
    750: {
      slidesToShow: 1
    }
  }
}

export default class Carousel {
  constructor(el, config) {
    this.el = el;
    this.config = Object.assign({}, configDefault, config.options);
    this.callback = config.callback;
    this.containerWidth = document.querySelector(this.config.container).offsetWidth;
    this.width = this.containerWidth && el.offsetWidth > this.containerWidth ? this.containerWidth : el.offsetWidth;
    this.setResponsive();
    this.carouselContent = el.firstElementChild;
    this.slides = this.carouselContent.querySelectorAll(`.${this.config.clsSlide}`);
    this.arrayOfSlides = Array.from(this.slides);
    if(this.arrayOfSlides.length === 0) return;

    this.slideWidth = this.width  / this.config.slidesToShow;
    this.btnNext = el.querySelector(this.config.btnNext);
    this.btnPrev = el.querySelector(this.config.btnPrev);
    this.dotElement = el.querySelector(this.config.dots);
    this.handlerTransition = function(){};
    this.autoPlayInterval = null
    this.canMove = true;
    this.initialX = 0;
    this.currentHeight = 'auto'
    this.windowWidth = window.innerWidth

    window.addEventListener('load', this.init.bind(this))
    window.addEventListener('resize', this.onResize.bind(this))
    window.addEventListener('visibilitychange', () => {
      this.stopAutoPlay()
      if(!document.hidden && this.config.isAutoPlay) this.autoPlay()
    })
  }

  /**
   * Init carousel
   */
  init() {
    // const { slidesToShow, slidesGroup } = this.config;
    // const activeChild = this.arrayOfSlides.slice(0, slidesToShow/slidesGroup);
    // this.activeNavigate(activeChild);
    this.setCrouselHeight()

    this.setupCarousel();
    this.setPrev();
    this.initNavigateButtons();
    if(this.config.canSwipe) this.initSwipe();

    if (this.config.isAutoPlay) {
      this.autoPlay()
    }
  }

  setCrouselHeight () {
      const allSlide = document.querySelectorAll('.carousel-slide')
      this.currentHeight = [...allSlide].filter(el => el.classList.toString().indexOf('is-clone') < 0)
        .reduce((clientHeight, slide) => {
          const children = slide && slide.childNodes
          const totalHeight = [...children].reduce((h, child) => (child && child.clientHeight || 0) + h, 0)
          return Math.max(clientHeight, totalHeight || 0)
        }, 0)

      this.el.style.height = this.currentHeight + 'px'
  }

  setupCarousel() {
    const { clsSlide, clsContent, slidesMargin, slidesToShow, clsActive } = this.config;
    this.carouselContent.classList.add(clsContent);
    let initialWidth = -this.slideWidth;

    this.arrayOfSlides.forEach((el, index) => {
      if(el.classList === null || el.classList === undefined) return;
      el.classList.add(clsSlide);
      el.style.width = this.slideWidth + "px";
      el.style.left = initialWidth + "px";
      initialWidth += this.slideWidth + slidesMargin;

      if (index < slidesToShow) {
        el.classList.add(clsActive)
      }

      if (this.callback) {
        el.addEventListener('click', () => this.callback(el));
      }
    });
  }

  autoPlay() {
    if(!this.config.isAutoPlay) return
    this.autoPlayInterval = setInterval(() => this.next(), this.config.duration)
  }

  stopAutoPlay() {
    clearInterval(this.autoPlayInterval)
  }

  initNavigateButtons() {
    if(this.btnPrev) this.btnPrev.addEventListener('click', this.prev.bind(this));
    if(this.btnNext) this.btnNext.addEventListener('click', this.next.bind(this));
  }

  setPrev() {
    const { clsSlide, clsActive, slidesToShow, slidesMargin } = this.config;
    const slides = this.el.querySelectorAll(`.${clsSlide}`);
    const slidesArray = Array.from(slides);

    // active navigate
    // this.activeNavigate();

    let width = 0;
    slidesArray.forEach((el, i) => {
      el.style.left = width + "px";
      el.classList.remove(clsActive);
      if(i < slidesToShow) {
        el.classList.add(clsActive);
      }
      width += this.slideWidth + slidesMargin;
    });

    this.addClone();
  }

  addClone() {
    const lastSlide = this.carouselContent.lastElementChild.cloneNode(true);
    lastSlide.classList.add(this.config.clsClone);
    lastSlide.style.left = (-this.slideWidth - this.config.slidesMargin) + "px";
    if (this.callback) {
      lastSlide.addEventListener('click', () => this.callback(lastSlide));
    }
    this.carouselContent.insertBefore(lastSlide, this.carouselContent.firstChild);
  }

  removeClone() {
    const firstSlide = this.carouselContent.firstElementChild;
    firstSlide.parentNode.removeChild(firstSlide);
  }

  activateAgain() {
    if(this.canMove) return;
    let firstSlide = this.carouselContent.firstElementChild;
    this.canMove = true;
    firstSlide.removeEventListener('transitionend', this.handlerTransition);
  }

  prev() {
    if ( !this.canMove ) return;
    this.canMove = false;
    let lastSlide = this.carouselContent.lastElementChild;
    lastSlide.parentNode.removeChild(lastSlide);
    this.carouselContent.insertBefore(lastSlide, this.carouselContent.firstChild);
    this.removeClone();
    this.stopAutoPlay()

    this.el.classList.remove('next');
    this.el.classList.add('prev');

    let firstSlide = this.carouselContent.firstElementChild;

    this.handlerTransition = this.activateAgain.bind(this);
    firstSlide.addEventListener('transitionend', this.handlerTransition, true);

    this.setPrev();
    this.autoPlay()
  }

  setNext() {
    const { clsSlide, slidesMargin, clsActive, slidesToShow } = this.config;
    const slides = this.el.querySelectorAll(`.${clsSlide}`);

    let slidesArray = Array.from(slides);
    slidesArray = slidesArray.reverse();

    // let maxWidth = ((slidesArray.length - 1) * (this.slideWidth + slidesMargin));
    // maxWidth += this.slideWidth + slidesMargin;

    // this.activeNavigate();
      slidesArray.forEach((el, i) => {
        const currentLeft = parseInt(el.style.left.replace('px', ''))

        el.style.left = currentLeft - (this.slideWidth + slidesMargin) + "px";
        el.classList.remove(clsActive);

        if (i < slidesArray.length - 2 && i > (slidesArray.length - slidesToShow - 3)) {
          el.classList.add(clsActive)
        }
      });
  }

  next() {
    if(!this.canMove) return;
    this.canMove = false;
    this.setNext();
    this.stopAutoPlay()

    this.el.classList.remove('prev');
    this.el.classList.add('next');

    this.handlerTransition = this.replaceToEnd.bind(this);

    const firstSlide = this.carouselContent.firstElementChild.nextSibling;
    firstSlide.addEventListener('transitionend', this.handlerTransition, true);
    this.autoPlay()
  }

  replaceToEnd() {
    this.removeClone()

    const firstSlide = this.carouselContent.firstElementChild;
    firstSlide.parentNode.removeChild(firstSlide);
    this.carouselContent.appendChild(firstSlide);

    firstSlide.style.left = ((this.arrayOfSlides.length - 1) * this.slideWidth) + this.config.slidesMargin * (this.arrayOfSlides.length - 1) + "px";

    this.addClone();
    this.canMove = true;
    this.removeEventListener(firstSlide, 'transitionend');
  }

  initSwipe() {
    const passiveEvents = detectIt.passiveEvents;
    this.carouselContent.addEventListener('mousedown', this.setInitialX.bind(this), passiveEvents ? { passive: true } : false );
    this.carouselContent.addEventListener('touchstart', this.setInitialX.bind(this), passiveEvents ? { passive: true } : false );

    this.carouselContent.addEventListener('mouseup', this.checkSwipe.bind(this), passiveEvents ? { passive: true } : false);
    this.carouselContent.addEventListener('touchend', this.checkSwipe.bind(this), passiveEvents ? { passive: true } : false);
  }

  setInitialX(e) {
    this.initialX = e.clientX || e.touches[0].clientX;
  }

  checkSwipe(e) {
    const { minSwipeValue } = this.config;
    const endPosX = e.clientX || e.changedTouches[0].clientX;
    const posXChanged = this.initialX - endPosX;

    if(Math.abs(posXChanged) < minSwipeValue) return;

    if(posXChanged > 0) this.next();
    else this.prev();
  }

  /**
   * active dots
   * @param {Array} elements - Optional
   */
  activeNavigate(elements) {
    const { clsActive, slidesGroup } = this.config;
    setTimeout(() => {
      let childs = elements || Array.from(this.el.querySelectorAll(`.${clsActive}`)) || [];
      if(childs.length === 0) return;

      if(slidesGroup > 1) childs = [childs[slidesGroup-1]];
      const dotchilds = Array.from(this.dotElement.childNodes);
      dotchilds.forEach(dot => dot.classList.remove('active'))

      childs.forEach( child => {
        if(!child) return;
        const { id } = child.dataset;
        const item = this.dotElement.querySelector(`.dots-item${id}`)

        item.classList.add('active');
      })
    }, 100);
  }

  /**
   * check responsive point
   */
  setResponsive() {
    const screenWidth = window.screen.width || window.innerWidth;

    const { responsive } = this.config;
    const responseArray = Object.entries(responsive);

    let responsiveSize = 10000;
    let config = {};

    responseArray.forEach(item => {
      const width = parseInt(item[0]);
      if(width > responsiveSize) return;
      responsiveSize = width;
      config = item[1];
    })

    if(screenWidth <= responsiveSize) {
      this.config = Object.assign({}, this.config, config);
    }
  }

  /**
   *
   * @param {ELement} el
   * @param {String} event
   */
  removeEventListener(el, event) {
    el.removeEventListener(event, this.handlerTransition, true);
  }

  onResize () {
    this.stopAutoPlay()
    // Only apply when resize horizontal
    if(window.innerWidth !== this.windowWidth && this.canMove) {
      const container = document.querySelector(this.config.container)
      const slides = Array.from(document.querySelectorAll(`.${this.config.clsSlide}`))
      if (!container) { return }
      setTimeout(() => this.setCrouselHeight(), 1100)

      this.windowWidth = window.innerWidth

      this.containerWidth = container.offsetWidth
      this.width =
        this.containerWidth && this.el.offsetWidth > this.containerWidth
          ? this.containerWidth
          : this.el.offsetWidth

      this.slideWidth = this.width / this.config.slidesToShow
      let initialWidth = -this.slideWidth - this.config.slidesMargin;

      slides.forEach(el => {
        el.style.width = this.slideWidth + 'px'
        el.style.left = initialWidth + "px";
        initialWidth += this.slideWidth + this.config.slidesMargin;
      })
    }
    this.autoPlay()
  }

}
