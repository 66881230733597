import { GetterTree, MutationTree } from 'vuex'

export const state = () => ({
  isShowMenu: false
})

export type StoreState = ReturnType<typeof state>

export const getters: GetterTree<StoreState, StoreState> = {
  isShowMenu: state => state.isShowMenu
}

export const mutations: MutationTree<StoreState> = {
  SET_MENU: (state, value: boolean) => (state.isShowMenu = value)
}
