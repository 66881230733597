import Vue from 'vue'
Vue.directive('page', {
  inserted: (_, binding) => {
    const options = Object.assign({}, binding.value)
    document.body.classList.add(options.name || binding.expression?.replace(/['"]/g, ''))
  },
  unbind: (_, binding) => {
    const options = Object.assign({}, binding.value)
    document.body.classList.remove(options.name || binding.expression?.replace(/['"]/g, ''))
  }
})
