export default [
  { from: '^/technologies[/]?$', to: '/works/', statusCode: 301 },
  { from: '^/rl[/]?$', to: '/works/yokohama-dena-baystars-team/', statusCode: 301 },
  { from: '^/ds[/]?$', to: '/kaggle/', statusCode: 301 },
  { from: '^/work11[/]?$', to: '/works/multipurpose-dam/', statusCode: 301 },
  { from: '^/work10[/]?$', to: '/works/vguard-tts/', statusCode: 301 },
  { from: '^/work7[/]?$', to: '/works/yokohama-dena-baystars-team/', statusCode: 301 },
  { from: '^/work8[/]?$', to: '/works/kepco-coal-fired-pp/', statusCode: 301 },
  { from: '^/work3[/]?$', to: '/works/game-othellonia-ai/', statusCode: 301 }
]
