import { ObserverOptions } from './interface'

const DefaultOptions: ObserverOptions = {
  y: '0px',
  ratio: 0,
  initClassName: 'observer',
  visibleClassName: 'visible',
  unVisibleClassName: 'un-visible',
  once: false,
  direction: false,
  callback: () => {}
}

export const CacheKey = '_intersectionObserverElement'

export default class IntersectionObserverElement {
  public element: any = null
  public options: ObserverOptions = DefaultOptions
  public observer: any = null
  public isVisible: boolean = false
  public prevRatio: number = 0
  public direction: string = ''

  constructor (element: Element, options: object) {
    this.element = element
    this.options = { ...DefaultOptions, ...(options || {}) }
    this.bind()
    this.element.classList.add(this.options.initClassName)
  }

  bind () : void {
    if (this.observer) {
      return
    }

    this.observer = new IntersectionObserver(
      this.onObserver.bind(this),
      {
        rootMargin: `${this.options.y} 0px`,
        threshold: [this.options.ratio]
      }
    )
    this.observer.observe(this.element)
  }

  unbind (): void {
    if (!this.observer) {
      return
    }

    this.observer.disconnect()
    this.observer = null
    this.element[CacheKey] = null
  }

  onVisible (): void {
    this.element.classList.remove(this.options.unVisibleClassName)
    this.element.classList.add(this.options.visibleClassName)
  }

  onUnVisible (): void {
    this.element.classList.remove(this.options.visibleClassName)
    this.element.classList.add(this.options.unVisibleClassName)
  }

  onObserver (entries: any): void {
    const entry = entries[0]
    const isVisible = entry.isIntersecting

    if (this.options.direction) {
      const { bottom } = entry.target.getBoundingClientRect()

      if (bottom < this.prevRatio) {
        this.direction = 'up'
      } else {
        this.direction = 'down'
      }

      this.prevRatio = bottom
    }

    if (this.isVisible === isVisible) {
      return
    }

    this.isVisible = isVisible

    if (this.isVisible) {
      this.onVisible()
    } else {
      this.onUnVisible()
    }

    if (this.options.callback) {
      this.options.callback(this)
    }

    if (this.isVisible && this.options.once) {
      this.unbind()
    }
  }
}
