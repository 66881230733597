import 'intersection-observer'
import Vue from 'vue'

import { ObserverOptions } from './interface'
import IntersectionObserverElement, { CacheKey } from './IntersectionObserverElement'

export default (_ctx: any, inject: any) => {
  inject('createIntersectionObserverElement', createIntersectionObserverElement)
}

/**
 *
 * @param {element} element Target element
 * @param {object} options Options
 * @return {IntersectionObserverElement} Observer
 */
function createIntersectionObserverElement (element: any, options: ObserverOptions): IntersectionObserverElement {
  const observer = new IntersectionObserverElement(element, options)
  element[CacheKey] = observer
  return observer
}

Vue.directive('intersection-observer', {
  inserted: (element, binding) => {
    let options = binding.value || {}
    if (typeof options === 'function') {
      options = {
        callback: options
      }
    }

    if (binding.modifiers.once) {
      options.once = true
    }

    createIntersectionObserverElement(element, options)
  }
})
