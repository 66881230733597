import { Context } from '@nuxt/types'

import routerLink from '../router-link'

const routerPlugin = (context: Context, inject: Function) => {
  context.$link = routerLink
  inject('link', routerLink)
}

export type RouterLinkType = typeof routerLink
export default routerPlugin
