import { Context } from '@nuxt/types'

import redirectList from '../config/redirect'

export default function (ctx: Context) {
  const route = ctx.route

  redirectList.forEach(({ from, to, statusCode }) => {
    if (route.fullPath.match(from)) {
      ctx.redirect(statusCode, to)
    }
  })
}
