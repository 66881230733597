type Position = {
  x: number
  y: number
}
const BREAK_POINT = 960
let mouse: Position = { x: 0, y: 0 }

export default class Cursor {
  el: HTMLElement
  cursorElement: HTMLElement
  isPC: boolean
  devicdeWidth: number
  isActive: boolean

  constructor (el: HTMLElement) {
    this.el = el
    this.cursorElement = document.querySelector('.js-cursor') as HTMLElement
    this.isActive = false
    this.devicdeWidth = window.innerWidth || screen.width
    this.isPC = this.devicdeWidth > BREAK_POINT
    if (!this.cursorElement || !this.isPC) { return }

    this.init()
    this.tracking()
  }

  init () {
    this.el.addEventListener('mousemove', this.requestAnimationFrame.bind(this), true)
    this.el.addEventListener('mouseleave', this.hide.bind(this), true)
    this.el.addEventListener('mouseover', () => {
      setTimeout(this.show.bind(this), 100)
    }, true)
  }

  requestAnimationFrame (): void {
    window.requestAnimationFrame(this.show.bind(this))
  }

  show (): void {
    this.cursorElement.style.display = 'block'
    this.cursorElement.style.top = `${mouse.y - 40}px`
    this.cursorElement.style.left = `${mouse.x - 40}px`
    this.cursorElement.style.opacity = '1'
  }

  hide (): void {
    setTimeout(() => {
      this.cursorElement.style.opacity = '0'
      this.cursorElement.style.display = 'none'
    }, 100)
  }

  setMousePosition (ev: MouseEvent) {
    mouse = {
      x: ev.clientX,
      y: ev.clientY
    }
  }

  tracking (): void {
    window.addEventListener('mousemove', this.setMousePosition.bind(this), true)
  }

  stop (): void {
    window.removeEventListener('mousemove', this.setMousePosition, true)
    this.el.removeEventListener('mousemove', this.requestAnimationFrame, true)
    this.el.removeEventListener('mouseout', this.hide, true)
  }
}
