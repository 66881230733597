import { NewsTagItem } from '@/models'

export default [
  {
    key: '登壇・資料公開',
    count: 0
  },
  {
    key: '研究開発',
    count: 0
  },
  {
    key: 'データサイエンス',
    count: 0
  },
  {
    key: 'Kaggle',
    count: 0
  },
  {
    key: 'プレスリリース',
    count: 0
  }
] as NewsTagItem[]
