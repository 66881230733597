import { Context } from '@nuxt/types'
import VueScrollTo from 'vue-scrollto'
/**
 * ================= USAGE ===============
 * this.$location.checkHash(hash, options);
 *
 * options = {
 *   offset: 0,       | Offset top
 *   duration: 600,   | Duration sroll to element
 *   reset: true      | Remove hash after scrolled to element
 * }
 * =======================================
 */

interface LocationOptions {
  offset?: number,
  duration?: number,
  reset?: boolean
}

const configDefault: LocationOptions = {
  offset: 0,
  duration: 600,
  reset: false
}

class LocationHandler {
  private options: LocationOptions = {}

  /**
   *  Two params
   * @param {Number} hash Hash string
   * @param {Number} options Options
   * @param {Boolean} reset Reset
   */
  checkHash (hash: string, options: LocationOptions = {}): void {
    this.options = Object.assign(configDefault, options)
    if (!hash) {
      return
    }

    const element = document.querySelector(hash)
    if (!element) {
      return
    }

    this._handleHash(element)
  }

  _handleHash (element: Element): void {
    if (!element) {
      return
    }

    const { pathname, search, origin } = window.location
    const { reset } = this.options

    setTimeout(() => {
      this._scrollToElement(element)
    }, 800)

    if (!reset) {
      return
    }

    this._resetURL(origin + pathname + search)
  }

  /**
   * Use 3rd library VueScrollto
   * @param {Element} element
   */
  _scrollToElement (element: Element): void {
    const { offset, duration } = this.options

    VueScrollTo.scrollTo(element, duration || 0, {
      offset
    })
  }

  /**
   * Remove hash in location
   * @param {String} url
   */
  _resetURL (url: string): void {
    window.history.replaceState(null, '', url)
  }
}

const location = new LocationHandler()
export type LocationType = typeof location

export default (ctx: Context, inject: any) => {
  ctx.$location = location
  inject('location', location)
}
